<template>
  <section>
    <!-- <vpn-header ref="header"></vpn-header> -->
    <div class="reg-wrapper" v-loading="loading">
      <div class="reg-body">
        <h2>Adventure starts here</h2>
        <el-form
          class="login-form"
          ref="regForm"
          :model="regForm"
          :rules="regRules"
          :inline="true"
          label-width="0">
          <!-- <el-form-item class="reg-half" prop="firstName">
            <el-input type="text" v-model="regForm.firstName" maxlength="30" placeholder="First Name">
            </el-input>
          </el-form-item>
          <el-form-item class="reg-half" prop="lastName">
            <el-input type="text" v-model="regForm.lastName" maxlength="30" placeholder="Last Name">
            </el-input>
          </el-form-item> -->
          <el-form-item class="reg-full" prop="username">
            <el-input type="text" v-model="regForm.username" maxlength="30" placeholder="Username">
            </el-input>
          </el-form-item>
          <el-form-item class="reg-full" prop="email">
            <el-input type="email" v-model="regForm.email" maxlength="30" placeholder="Email">
            </el-input>
          </el-form-item>
          <el-form-item class="reg-full" prop="password">
            <el-input type="password" v-model="regForm.password" maxlength="20" placeholder="Password" show-password>
            </el-input>
          </el-form-item>
          <el-form-item class="reg-full" prop="password2">
            <el-input type="password" v-model="regForm.password2" maxlength="20" placeholder="Password Confirmation" show-password>
            </el-input>
          </el-form-item>
          <!-- <el-form-item class="reg-full" prop="country">
            <el-select v-model="regForm.country" filterable placeholder="Country">
              <el-option
                v-for="(item, index) in countrys"
                :key="index"
                :label="item.countryEnname"
                :value="item.countryEnname">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item class="reg-full">
            <el-checkbox v-model="regForm.agree">I agree to privacy policy & terms</el-checkbox>
          </el-form-item>
          <el-form-item class="reg-full login-submit">
            <el-button type="primary" @click="handleSign">Sign Up</el-button>
          </el-form-item>
        </el-form>
        <dl class="login-forgot">
          <dd>Already have an account? <el-link type="primary" @click="gotoLogin">
Sign in instead</el-link></dd>
        </dl>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from 'vuex'
import md5 from 'js-md5'
import VpnHeader from '@/components/layout/header'
import { getCountry, reg } from '@/apis/common'

export default {
  components: { VpnHeader },
  props: {},
  data () {
    var validateEmail = (rule, value, callback) => {
      // console.log(value)
      // const regex = /^.+@gmail\.com$/;
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(value)) {
        callback(new Error('Must use email!'));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.regForm.password) {
        callback(new Error('The Confirm Password field confirmation does not match!'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      regForm: {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        password2: '',
        country: '',
        agree: false
      },
      regRules: {
        // firstName: [
        //   { required: true, message: 'FirstName', trigger: 'blur' }
        // ],
        // lastName: [
        //   { required: true, message: 'LastName', trigger: 'blur' }
        // ],
        username: [
          { required: true, message: 'Username', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Email', trigger: 'blur' },
          // { type: 'email', message: 'Please enter the correct email', trigger: ['blur'] },
          { validator: validateEmail, trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Password', trigger: 'blur' },
          { min: 6, max: 20, message: 'Password length between 6-20 digits', trigger: 'blur' }
        ],
        password2: [
          { required: true, message: 'Password Confirmation', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ],
        // country: [
        //   { required: true, message: 'Country', trigger: 'change' }
        // ]
      },
      countrys: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations(['setUserInfo']),
    init() {
      // getCountry({}, (res) => {
      //   // console.log(res)
      //   this.countrys = res
      // }, (err) => {
      //   // console.log(err)
      // })
    },
    handleSign() {
      this.$refs['regForm'].validate(valid => {
        if (valid) {
          if (!this.regForm.agree) {
            this.$message.warning('Please confirmed that you have read and agreed to our Terms and Conditions and Privacy Policy.')
            return false
          }
          console.log(this.regForm)
          this.loading = true
          reg({
            firstName: this.regForm.firstName,
            lastName: this.regForm.lastName,
            username: this.regForm.username,
            email: this.regForm.email,
            password: md5(this.regForm.password),
            country: this.regForm.country
          }, (res) => {
            // console.log(res)
            this.setUserInfo(res)
            this.loading = false
            this.$alert('Sign up success,Please go to your email and click on the activation link', {
              showClose: false,
              confirmButtonText: 'Confirm',
              callback: action => {
                this.$router.push('/login')
              }
            });
          }, (err) => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    gotoLogin() {
      this.$router.push('/login')
    }
  },
  created () {
    this.init()
  },
  mounted () {},
  destroyed () {}
}
</script>
